.Link {
  display: inline-block;
}
.Link_type1 {
  color: #1c49ca;
  text-decoration: none;
}
.Link_type1:hover {
  text-decoration: underline;
}
.Link_type2 {
  color: #3e3a39;
  text-decoration: none;
}
.Link_type2:hover {
  text-decoration: underline;
  color: #3e3a39;
}
.Link_type3,
.Link_type5,
.Link_type6 {
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid transparent;
  transition: background 250ms linear;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #d4b390;
  border-color: transparent;
  text-align: center;
  text-decoration: none;
  color: #fff;
}
.Link_type3:hover,
.Link_type5:hover,
.Link_type6:hover {
  background-color: #ae937c;
}
.Link_type3:active,
.Link_type5:active,
.Link_type6:active {
  background-color: #ae937c;
}
.Link_type3.Link_is-disabled,
.Link_type5.Link_is-disabled,
.Link_type6.Link_is-disabled {
  background: #c6b3a3;
  cursor: default;
  pointer-events: none;
}
.Link_type3.Link_is-disabled:hover,
.Link_type5.Link_is-disabled:hover,
.Link_type6.Link_is-disabled:hover {
  background: #c6b3a3;
}
.Link_type4 {
  text-decoration: none;
  color: #3e3a39;
}
.Link_type4:hover {
  color: #ef7f1a !important;
}
.Link_type4:active {
  color: #3e3a39;
}
.Link_type4:visited {
  color: #3e3a39;
}
.Link_is-block {
  width: 100%;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .Link_type5,
  .Link_type6 {
    max-height: 270px;
    height: 100vw;
    width: 150px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
  .Link_type5:before,
  .Link_type6:before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    padding-bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
.Link_type5 {
  background: #eae9e9;
  color: #3e3a39;
}
.Link_type5:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzNSIgdmlld0JveD0iMCAwIDM5IDM5Ij4KICA8cGF0aCBkPSJNMTkuNSwwQTE5LjUsMTkuNSwwLDEsMCwzOSwxOS41LDE5LjUsMTkuNSwwLDAsMCwxOS41LDBaTTM0Ljc1LDIwLjA3YTEuMzQsMS4zNCwwLDAsMS0uMzIuNDlsLTkuNTQsOS41M2ExLjQ1LDEuNDUsMCwwLDEtMS4wNi40NCwxLjQ4LDEuNDgsMCwwLDEtMS4wNi0uNDQsMS41MSwxLjUxLDAsMCwxLDAtMi4xMmw3LTdINS42M2ExLjUsMS41LDAsMSwxLDAtM0gyOS43NWwtNy03YTEuNTEsMS41MSwwLDAsMSwwLTIuMTIsMS40OSwxLjQ5LDAsMCwxLDIuMTIsMGw5LjU0LDkuNTNhMS4zNCwxLjM0LDAsMCwxLC4zMi40OXYwYTEuNDMsMS40MywwLDAsMSwwLDEuMVoiIGZpbGw9IiNhZGFkYWQiLz4KPC9zdmc+Cg==);
}
.Link_type5:hover,
.Link_type5:active {
  background: #eae9e9;
  color: #3e3a39;
}
.Link_type6 {
  background: #d4b390;
  color: #fff;
}
.Link_type6:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzNSIgdmlld0JveD0iMCAwIDM5IDM5Ij4KICA8cGF0aCBkPSJNMTkuNSwwQTE5LjUsMTkuNSwwLDEsMCwzOSwxOS41LDE5LjUsMTkuNSwwLDAsMCwxOS41LDBaTTM0Ljc1LDIwLjA3YTEuMzQsMS4zNCwwLDAsMS0uMzIuNDlsLTkuNTQsOS41M2ExLjQ1LDEuNDUsMCwwLDEtMS4wNi40NCwxLjQ4LDEuNDgsMCwwLDEtMS4wNi0uNDQsMS41MSwxLjUxLDAsMCwxLDAtMi4xMmw3LTdINS42M2ExLjUsMS41LDAsMSwxLDAtM0gyOS43NWwtNy03YTEuNTEsMS41MSwwLDAsMSwwLTIuMTIsMS40OSwxLjQ5LDAsMCwxLDIuMTIsMGw5LjU0LDkuNTNhMS4zNCwxLjM0LDAsMCwxLC4zMi40OXYwYTEuNDMsMS40MywwLDAsMSwwLDEuMVoiIGZpbGw9IiNmZmZmZmYiLz4KPC9zdmc+Cg==);
}
.Link_type6:hover,
.Link_type6:active {
  background: #d4b390;
  color: #fff;
}
.Link_type7 {
  border: 1px solid #d2d2d2;
  padding: 10px;
  border-radius: 7px;
  box-sizing: border-box;
  color: #3e3a39;
  cursor: pointer;
  font-weight: bold;
  transition: border-color 150ms ease-in-out;
  text-decoration: none;
}
.Link_type7:hover {
  border-color: #adadad;
}
.Link_type7:visited {
  color: #3e3a39;
}
.Link_type8 {
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 35px;
  height: 35px;
  text-decoration: none;
  margin-right: 7px;
  font-weight: bold;
  transition: background-color 250ms;
  color: #3e3a39;
}
.Link_type8:visited {
  color: #3e3a39;
}
