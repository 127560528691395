.Heading {
  margin: 0;
  transition: 250ms font-size;
}
.Heading_is-hidden {
  display: none;
}
.Heading_is-bold {
  font-weight: bold;
}
.Heading_color-dark {
  color: #3e3a39;
}
.Heading_color-primary {
  color: #d4b390;
}
.Heading_color-white {
  color: #fff;
}
.Heading_level-1 {
  font-size: 30px;
}
@media only screen and (max-width: 768px) {
  .Heading_level-1 {
    font-size: 25px;
  }
}
.Heading_level-2 {
  font-size: 25px;
}
@media only screen and (max-width: 768px) {
  .Heading_level-2 {
    font-size: 20px;
  }
}
.Heading_level-3 {
  font-size: 20px;
}
@media only screen and (max-width: 768px) {
  .Heading_level-3 {
    font-size: 17px;
  }
}
.Heading_level-4 {
  font-size: 18px;
}
@media only screen and (max-width: 768px) {
  .Heading_level-4 {
    font-size: 15px;
  }
}
.Heading_level-5 {
  font-size: 16px;
}
@media only screen and (max-width: 768px) {
  .Heading_level-5 {
    font-size: 14px;
  }
}
.Heading_level-6 {
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .Heading_level-6 {
    font-size: 12px;
  }
}
